<template>
  <div class="home">
    <Slider />
    <Overview
      id="overview"
    />
    <Features
      id="features"
    />
    <Integrations
      id="integrations"
    />
    <GetStarted
      id="getStarted"
    />
  </div>
</template>

<script>
import Slider from '../components/Home/Slider.vue'
import Overview from '../components/Home/Overview.vue'
import Features from '../components/Home/Features.vue'
import Integrations from '../components/Home/Integrations.vue'
import GetStarted from '../components/Home/GetStarted.vue'

export default {
  name: 'Home',
  components: {
    Slider,
    Overview,
    Features,
    Integrations,
    GetStarted,
  },

  props: {
    section: {
      type: String,
      default: '',
    },
    watcher: {
      type: Number,
      default: 0,
    },
  },

  /* eslint-disable */
  watch: {
    watcher(newValue, oldValue) {
      this.scrollTo(this.section)
    },
  },
  /* eslint-enable */

  methods: {
    scrollTo(section) {
      const id = section
      const element = document.getElementById(id)
      if (section === 'overview') {
        const yOffset = -180
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      } else if (section === 'features') {
        const yOffset = -170
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      } else if (section === 'integrations') {
        const yOffset = -75
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      } else if (section === 'getStarted') {
        const yOffset = -200
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      } else if (section === 'about') {
        window.scrollTo({
          top: document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: 'smooth',
        })
      } else if (section === '') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .home {
    background-image: url("../assets/images/background.png");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
</style>
