<template>
  <div class="overview">
    <div class="container">
      <b-row class="row">
        <b-col
          class="topLeft column"
          xl="4"
          lg="12"
        >
          <div class="container-fluid">
            <h1
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              OVERVIEW
            </h1>
            <b-img
              data-aos="fade-up"
              data-aos-duration="1300"
              src="@/assets/images/overview/stats.png"
              class="img"
            />
          </div>
        </b-col>

        <b-col
          class="topRight column"
          xl="8"
          lg="12"
        >
          <div
            class="container"
          >
            <b-row>
              <b-col
                style="padding: 0"
                xl="4"
                lg="4"
                md="4"
                sm="12"
              >
                <b-card
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  class="cards orangeCard"
                >
                  <b-img
                    class="icon"
                    src="@/assets/images/icons/checkmark.png"
                  />
                  <h3>
                    Box Content
                  </h3>
                  <p>
                    A quick and efficient way of <br>
                    tracking box content <br>
                    information and submitting it <br>
                    to Amazon as required.
                  </p>
                </b-card>
                <b-card
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  class="cards darkCard"
                >
                  <b-img
                    class="icon"
                    src="@/assets/images/icons/overview.png"
                  />
                  <h3 style="color: darkorange">
                    Overview of <br> Shipments
                  </h3>
                  <p>
                    The shipment list interface <br>
                    allows our users to easily <br>
                    review their full shipment <br>
                    history, helping sellers plan <br>
                    out their next upcoming <br>
                    shipments.
                  </p>
                </b-card>
              </b-col>

              <b-col
                style="padding: 0"
                xl="4"
                lg="4"
                md="4"
                sm="12"
              >
                <b-card
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  class="cards grayCard"
                >
                  <b-img
                    class="icon"
                    src="@/assets/images/icons/smartphone.png"
                  />
                  <h3 style="color: black">
                    User Friendly <br> Interface
                  </h3>
                  <p>
                    Our user friendly interface <br>
                    allows new users to quickly <br>
                    adapt to the new system <br>
                    dashboard, shipments and <br>
                    other sections that Fulfillin <br>
                    provides.
                  </p>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col
          class="botLeft column"
          xl="6"
          lg="12"
        >
          <div class="container-fluid">
            <h1
              data-aos="fade"
              data-aos-duration="1000"
            >
              Manage your shipments <br> like never before
            </h1>
            <h3
              data-aos="fade"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              Smart way of managing your shipments using collected <br> statistics from previously completed shipments
            </h3>
          </div>
        </b-col>

        <b-col
          class="botRight column"
          xl="6"
          lg="12"
        >
          <div class="container-fluid">
            <h1
              data-aos="fade"
              data-aos-duration="1000"
            >
              Live Statistics
            </h1>
            <h3
              data-aos="fade"
              data-aos-duration="1000"
            >
              Follow up with live statistics on your dashboard to see
              your latest and previous shipments, shipment progress
              and much more.
            </h3>
            <div
              class="underline"
              data-aos="fade"
              data-aos-duration="1000"
            />
            <h1
              data-aos="fade"
              data-aos-duration="1000"
            >
              Performance tracking
            </h1>
            <h3
              data-aos="fade"
              data-aos-duration="1000"
            >
              Keep track of shipment fulfillment and be sure to never
              skip or miss something out regarding your shipments in
              progress.
            </h3>
            <div
              class="underline"
              data-aos="fade"
              data-aos-duration="1000"
            />
            <h1
              data-aos="fade"
              data-aos-duration="1000"
            >
              Planning & Scheduling
            </h1>
            <h3
              data-aos="fade"
              data-aos-duration="1000"
            >
              Store your shipments and schedule them beforehand.
              Keep shipping plans on Fulfillin so you are always aware
              of which shipment is the next to fulfill.
            </h3>
            <div
              class="underline"
              data-aos="fade"
              data-aos-duration="1000"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BCard,
} from 'bootstrap-vue'

export default {
  name: 'Overview',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
.overview {
  margin-top: -80px;

  .container {
    .row {
      .column {
        height: 800px;
        padding: 3px 3px 3px 3px;
        @media (max-width: 1199px) {
          height: auto;
          padding-left: 0;
          padding-right: 0;
        }

        .container-fluid, .container {
          height: 100%;
          box-shadow: black 3px 3px 10px;
          background-size: cover;
          background-position: center;
          padding-bottom: 10px;
          position: relative;
        }
      }

      .topLeft {
        .container-fluid {
          background-color: darkorange;

          h1 {
            font-size: 60px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            @media (max-width: 1199px) {
              text-align: center;
            }
          }

          .img {
            position: absolute;
            width: 120%;
            filter: brightness(70%);
            z-index: 3;
            box-shadow: 3px 3px 10px black;
            @media (max-width: 1199px) {
              width: 100%;
              position: relative;
              z-index: 0;
            }
          }
        }
      }

      .topRight {
        .container {
          background-image: url("../../assets/images/overview/overview1.png");
          background-size: 100%;
          padding-left: 15%;
          z-index: 1;
          border-bottom-left-radius: 350px;
          border-bottom-right-radius: 200px;
          @media (max-width: 767px) {
            padding-left: 15px;
          }
          @media (max-width: 1199px) {
            z-index: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .cards {
            min-height: 400px;
            padding-bottom: 20px;
            box-shadow: black 0 0 10px;
            color: white;
            position: relative;

            h3 {
              color: white;
              font-family: 'Barlow', sans-serif;
              margin-bottom: 30px;
              font-weight: bold;
              padding-bottom: 10px;
              border-bottom: 2px solid white;
            }

            p {
              font-family: 'Barlow', sans-serif;
              font-size: 16px;
            }

            .icon {
              margin-bottom: 50px;
            }
          }

          .orangeCard {
            background-color: darkorange;
            margin: -80px 3px 3px 3px;
            @media (max-width: 767px) {
              margin: 15px 3px 3px 3px;
            }
          }

          .darkCard {
            background-color: #1b1c1e;
            margin: 7px 3px 3px 3px;
            min-height: 445px;
            z-index: 10;
          }

          .grayCard {
            background-color: darkgray;
            margin: 25px 3px 3px 3px;
            min-height: 445px;
            @media (max-width: 991px) {
              margin: 3px;
            }
          }
        }
      }

      .botLeft {
        margin-top: -240px;
        @media (max-width: 1199px) {
          margin-top: 0;
        }
        .container-fluid {
          background-image: url("../../assets/images/overview/stats1.png");
          padding-top: 45%;
          padding-bottom: 40%;
          z-index: 0;
          @media (max-width: 1199px) {
            padding-top: 20%;
            padding-bottom: 20%;
          }

          h1, h3 {
            text-align: center;
            color: white;
            font-family: 'Open Sans', sans-serif;
          }
        }
      }

      .botRight {
        margin-top: -160px;
        height: 720px;
        @media (max-width: 1199px) {
          height: auto;
          margin-top: 0;
        }

        .container-fluid {
          border-top-left-radius: 200px;
          background-image: url("../../assets/images/overview/overview.png");
          padding: 180px 50px 150px 50px;
          z-index: 2;
          @media (max-width: 1199px) {
            border-radius: 0;
            z-index: 0;
          }

          h1 {
            font-family: 'Open Sans', sans-serif;
            color: black;
          }

          h3 {
            font-family: 'Barlow', sans-serif;
            color: white;
          }

          .underline {
            height: 3px;
            background-color: white;
            border-radius: 5px;
            width: 50%;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
</style>
