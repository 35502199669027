<template>
  <div class="getStarted">
    <div class="a">
      <div class="container-fluid">
        <h2
          data-aos="fade"
          data-aos-duration="1000"
          style="color: white;"
        >
          Get started today
        </h2>
        <button
          v-b-toggle.collapse-1
          class="btn"
          data-aos="fade"
          data-aos-duration="1000"
        >
          <b>
            GET STARTED
          </b>
        </button>
      </div>
    </div>

    <b-collapse id="collapse-1">
      <Pricing />
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse, VBToggle,
} from 'bootstrap-vue'

import Pricing from '@/components/Home/Pricing.vue'

export default {
  name: 'GetStarted',
  components: {
    BCollapse,
    Pricing,
  },

  directives: {
    'b-toggle': VBToggle,
  },
}
</script>

<style lang="scss" scoped>
.getStarted {
  .a {
    .container-fluid {
      position: relative;
      width: 100%;
      padding-top: 160px;
      text-align: center;
      color: white;
      background-image: url("../../assets/images/getStartedBackground.png");
      background-position: center;
      background-size: cover;

      h2 {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 60px;
        position: center;
        text-shadow: 0 0 20px black;
      }

      .btn {
        border-width: 3px;
        border-color: white;
        border-radius: 50px;
        height: 60px;
        width: 190px;
        font-size: 20px;
        background-color: white;
        color: darkorange;
        position: center;
        box-shadow: 0 0 10px black;
        margin-bottom: 160px;

        &:hover {
          background-color: darkorange;
          color: white;
          transition: all 1s ease;
        }
      }
    }
  }
}
</style>
