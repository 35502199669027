<template>
  <div class="pricing">
    <div class="container">
      <b-row class="match-height">
        <b-col
          class="column"
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            class="card"
            style="background-color: darkorange"
          >
            <b-card-body class="d-flex flex-column h-100">
              <h3>
                Basic Plan
              </h3>
              <h1>
                FREE
              </h1>
              <h5>
                Up to 2000 Products / Month
                <hr>
                Amazon FBA Integration
                <hr>
                Linnworks Integration
                <hr>
                Professional Tools
                <hr>
                Professional Dashboard
                <hr>
                Professional Reports
                <hr>
                Shipment Creation
                <hr>
                Product Scanning by Item / Item Pack
                <hr>
                Creating of Shipping Labels
                <hr>
                Stock Update for Shipped Items
                <hr>
                Unlimited Integrations
                <hr>
                Unlimited Selling Platforms
                <hr>
                Customer Support by Email
                <hr>
              </h5>
              <a
                class="mt-auto"
                href="https://app.fulfillin.com"
              >
                <button class="btn">
                  TRY NOW
                </button>
              </a>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="column"
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            class="card"
            style="background-color: white"
          >
            <b-card-body class="d-flex flex-column h-100">
              <h3 style="color: black">
                Pro Plan
              </h3>
              <h1 style="color: darkorange">
                Limited time offer - FREE
              </h1>
              <h5 style="color: black">
                Everything Included in Basic
                <hr>
                More than 2000 Products / Month
                <hr>
                API Access
                <hr>
                Customer Support by Email or Chat
                <hr>
              </h5>
              <a
                class="mt-auto"
                href="https://app.fulfillin.com"
              >
                <button
                  id="middle"
                  class="btn"
                >
                  FREE
                </button>
              </a>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="column"
          lg="4"
          md="12"
          sm="12"
        >
          <b-card
            class="card"
            style="background-color: #3F4349"
          >
            <b-card-body class="d-flex flex-column h-100">
              <h3 style="color: darkorange">
                Ultimate Plan
              </h3>
              <h1 style="color: white">
                Contact Us
              </h1>
              <h5 style="color: white">
                Everything Included in Pro
                <hr>
                Unlimited Products & Shipments
                <hr>
              </h5>
              <a
                class="mt-auto"
                href="https://app.fulfillin.com"
              >
                <button
                  id="right"
                  class="btn"
                >
                  Coming Soon
                </button>
              </a>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'Pricing',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
}
</script>

<style lang="scss" scoped>
.pricing {
  background-color: #1b1c1e;

  .container {

    .card {
      border-radius: 20px;
      padding: 20px 30px 20px 30px;
      text-align: center;
      position: relative;
      box-shadow: black 0 0 30px;
      height: 100%;
      margin: 20px 0 20px 0;

      h1 {
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: white;
        padding-bottom: 20px;
      }

      h3 {
        color: white;
        font-weight: bold;
        font-family: 'Barlow', sans-serif;
        margin-bottom: 15px;
      }

      h5 {
        font-family: 'Open Sans', sans-serif;
        color: white;
      }

      .btn {
        border-width: 3px;
        border-color: white;
        border-radius: 50px;
        height: 60px;
        width: 190px;
        font-size: 20px;
        background-color: white;
        color: darkorange;
        position: center;
        box-shadow: 0 0 5px black;

        &:hover {
          background-color: darkorange;
          color: white;
          transition: all .5s ease;
        }
      }

      #middle {
        background-color: darkorange;
        border-color: darkorange;
        color: white;

        &:hover {
          background-color: white;
          color: darkorange;
          transition: all .5s ease;
        }
      }

      #right {
        color: #3F4349;

        &:hover {
          background-color: #3F4349;
          color: white;
          transition: all .5s ease;
        }
      }
    }
  }
}
</style>
