<template>
  <div class="features">
    <div class="container">
      <b-row>

        <b-col
          md="12"
          lg="6"
        >
          <h3 data-aos="fade">
            Many features to select from
          </h3>
          <h5 data-aos="fade">
            Fulfillin provides multiple features which <br>
            makes work a lot faster and a lot more <br>
            sufficient, allowing users to focus on <br>
            other important things by granting extra <br>
            valuable time once shipments have been <br>
            fulfilled using Fulfillin.
          </h5>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              class="orangeBackground topTable"
              data-aos="zoom-in"
            >
              <img
                src="../../assets/images/icons/cart.png"
                alt=""
              >
              <p>
                Multiple options for label printing for
                products within the Amazon shipment.
                No need for Amazon interface.
                Single label and multiple label printing available.
              </p>
            </b-col>

            <b-col
              class="darkBackground topTable"
              data-aos="zoom-in"
            >
              <img
                src="../../assets/images/icons/support.png"
                alt=""
              >
              <p>
                Our customer support will help you
                with all your questions and concerns
                regarding the usage of Fulfillin. We
                are here for you 24 hours 7 days a
                week.
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="darkBackground botTable"
              data-aos="zoom-in"
            >
              <img
                src="../../assets/images/icons/stars.png"
                alt=""
              >
              <p>
                With Fulfillin it is easy to manage
                shipment products by by scanning
                the items before adding them to your
                shipment boxes. Great overview over
                what products are already handled
                and what items are still needed to be
                added to the shipment.
              </p>
            </b-col>

            <b-col
              class="orangeBackground botTable"
              data-aos="zoom-in"
            >
              <img
                src="../../assets/images/icons/graph.png"
                alt=""
              >
              <p>
                We are offering quick and simple way
                to provide box information
                by scanning products and adding them
                to a specific box. Box measurements
                can be set for every box separately.
                Information can be downloaded and
                uploaded to Amazon for quick box
                content handling.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Features',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style lang="scss" scoped>
.features {
  background-image: url('../../assets/images/features.jpg');
  background-position: center;
  background-size: cover;
  padding-bottom: 90px;
  padding-top: 90px;
  @media (max-width: 991px) {
    text-align: center;
  }

  .container {
    .orangeBackground {
      background-color: rgba(255.0, 140.0, 0.0, 0.61);
      border-radius: 10px;
      margin: 3px;
      padding-top: 20px;
      box-shadow: 1px 1px 10px black;
      padding-bottom: 20px;
    }

    .darkBackground {
      background-color: rgba(27.0, 28.0, 30.0, 0.61);
      border-radius: 10px;
      margin: 3px;
      padding-top: 20px;
      box-shadow: 1px 1px 10px black;
      padding-bottom: 20px;
    }
  }
}

img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

h3 {
  font-family: 'Poppins', sans-serif;
  color: darkorange;
  font-size: 30px;
  text-shadow: 1px 1px 10px black;
}

h5 {
  font-family: 'Barlow', sans-serif;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  text-shadow: 1px 1px 10px black;
}

p {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-size: 14px;
  text-shadow: 1px 1px 10px black;
}
</style>
