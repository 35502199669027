<template>
  <div class="integrations">
    <div class="container">
      <h1 data-aos="fade">
        INTEGRATIONS
      </h1>
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            data-aos="zoom-in"
            data-aos-duration="1000"
            class="card"
          >
            <a href="https://help.fulfillin.com/en/mirakl">
              <img
                src="../../assets/images/integrations/mirkal.png"
                alt=""
              >
            </a>
          </b-card>
        </b-col>

        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            data-aos="zoom-in"
            data-aos-duration="1000"
            class="card"
          >
            <a href="https://sell.amazon.com/fulfillment-by-amazon">
              <img
                src="../../assets/images/integrations/amazonfulfillment.png"
                alt=""
              >
            </a>
          </b-card>
        </b-col>

        <b-col
            lg="4"
            md="6"
            sm="12"
        >
          <b-card
              data-aos="zoom-in"
              data-aos-duration="1000"
              class="card"
          >
            <a href="https://www.linnworks.com/">
              <img
                  src="../../assets/images/integrations/linnworks.png"
                  alt=""
              >
            </a>
          </b-card>
        </b-col>

        <b-col
            lg="4"
            md="6"
            sm="12"
        >
          <b-card
              data-aos="zoom-in"
              data-aos-duration="1000"
              class="card"
          >
            <a href="https://help.fulfillin.com/en/dpd/dpd">
              <img
                  src="../../assets/images/integrations/dpd.png"
                  alt=""
              >
            </a>
          </b-card>
        </b-col>

        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            data-aos="zoom-in"
            data-aos-duration="1000"
            class="card"
          >
            <a href="https://help.fulfillin.com/en/dbschenker">
              <img
                src="../../assets/images/integrations/dbschenker.png"
                alt=""
              >
            </a>
          </b-card>
        </b-col>

        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            data-aos="zoom-in"
            data-aos-duration="1000"
            class="card"
          >
            <a href="https://help.fulfillin.com/en/omniva/omniva">
              <img
                src="../../assets/images/integrations/omniva.png"
                alt=""
              >
            </a>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BCard } from 'bootstrap-vue'

export default {
  name: 'Integrations',
  components: {
    BCol,
    BRow,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
.integrations {
  background-color: #1b1c1e;

  .container {
    padding-bottom: 24px;
    padding-top: 24px;
    text-align: center;
  }

  .card {
    box-shadow: 0 0 30px black;
  }

  h1 {
    color: white;
    font-size: 48px;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0 0 20px black;
  }

  img {
    transform: scale(1);
    transition: .5s ease all;
    width: 70%;

    &:hover {
      transform: scale(1.1);
      transition: .5s ease all;
    }
  }
}
</style>
