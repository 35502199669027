<template>
  <div class="slider">
    <div class="container">
      <h1
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <b>
          All in one Mirakl integration <br/> for Linnworks sellers
        </b>
      </h1>

      <h4
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="2000"
      >
        Our Mirakl app for Linnworks is easiest way to <br/> manage all your Mirakl marketplace business <br/> in one place
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
}
</script>

<style lang="scss" scoped>
.slider {
  background-image: url("../../assets/images/slider.jpg");
  background-position: center;
  background-size: cover;
  padding-bottom: 150px;
  @media (max-width: 1199px) {
    text-align: center;
  }

  .container {
    position: relative;
    flex-direction: row;
    margin: 0 auto;
  }

  h1, h4 {
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 0 0 10px black;
    text-align: left;
    @media (max-width: 1199px) {
      text-align: center;
    }
  }

  h1 {
    font-size: 70px;
    padding-top: 120px;
  }

  h4 {
    font-size: 30px;
    padding-top: 30px;
  }
}
</style>
